
















import { Component, Vue } from 'nuxt-property-decorator'

@Component<MStatsDecoration>({})
export default class MStatsDecoration extends Vue {
  scrollPosition = 0

  mounted() {
    if (process.client) {
      window.addEventListener('scroll', this.onScroll)
      this.onScroll()
    }
  }

  onScroll() {
    if (process.client) {
      this.scrollPosition = window.scrollY
    }
  }
}
