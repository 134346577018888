import { render, staticRenderFns } from "./PageStats.vue?vue&type=template&id=736c4fda&lang=pug&"
import script from "./PageStats.vue?vue&type=script&lang=ts&"
export * from "./PageStats.vue?vue&type=script&lang=ts&"
import style0 from "./PageStats.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageStatsBlocks: require('/var/www/imoli.app/components/Page/PageStats/PageStatsBlocks.vue').default,PageStatsBadges: require('/var/www/imoli.app/components/Page/PageStats/PageStatsBadges.vue').default,PageStatsDecoration: require('/var/www/imoli.app/components/Page/PageStats/PageStatsDecoration.vue').default})
